import React, { useEffect, useState } from "react";
import "./RideHistory.css";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import axios from "axios";

import { Paper, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const RideHistory = () => {
  const [rideData, setRideData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://api.holocabs.in/driverConfirm/ride-history",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setRideData(response.data.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const convertToIST = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const convertISOToIST = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const OpenWP = (type, number) => {
    if (!type || !number) return;

    // Define templates with proper structure and spacing
    const DriverTemplate = `
Namaskar Holo ତରଫ ରୁ 🙏🏻
Passenger ଅପେକ୍ଷା କରିଛନ୍ତି।
ଦୟା କରି pick-up ପାଇଁ ଯାଆନ୍ତୁ।
ଧନ୍ୟବାଦ୍🙏🏻😊
ଯଦି କିଛି issue ଆସେ ଆମକୁ ଏଠି ଜଣେଇବେ ଦୟା କରି cancel କରିବେ ନାହିଁ 🙏🏻
HOLO CABS RIDE DUE AMOUNT plus PICKUP charge ଆପଣଙ୍କ bank account ରେ ଜମା ହୋଇ ଯିବ। 🙏🏻😊
Extra pick-up distance ପାଇଁ extra charges ମିଳିବ company ତରଫ ରୁ 🙏🏻😊
`;

    const PassengerTemplate = `
Hello, from Holo Cabs.
Driver is reaching your pickup soon🙏🏻😊
You may call directly to the driver if you face IVR CONNECTIVITY ISSUES.
If you face any issues please reply here. Thank you, we sincerely request you not to cancel and give us a chance to serve you🙏🏻😊
`;

    // URL-encode the message to preserve structure and emojis
    const encodeMessage = (message) => encodeURIComponent(message);

    const template =
      type === "driver"
        ? encodeMessage(DriverTemplate)
        : encodeMessage(PassengerTemplate);

    // Create the WhatsApp link
    //const whatsappLink = `https://wa.me/${number}?text=${template}`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+91${number}&text=${template}`;

    // Open the link in a new tab
    window.open(whatsappLink, "_blank");
  };

  const verifyOTP = async (rideId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `https://api.holocabs.in/driver/drivers/${rideId}/destinationstart`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        alert("OTP Verified Successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    { field: "rideId", headerName: "Ride ID", width: 120 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "driverAcceptedAt", headerName: "Driver Accepted At", width: 180 },
    { field: "rideCompletedAt", headerName: "Ride Completed At", width: 180 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    {
      field: "customerId",
      headerName: "Customer ID",
      width: 180,
      renderCell: (params) => (
        <a href={`/users/${params.value}`}>{params.value}</a>
      ),
    },
    { field: "driverName", headerName: "Driver Name", width: 150 },
    { field: "vehicleType", headerName: "Vehicle Type", width: 150 },
    {
      field: "driverId",
      headerName: "Driver ID",
      width: 180,
      renderCell: (params) => (
        <a href={`/drivers/${params.value}`}>{params.value}</a>
      ),
    },
    { field: "driverMobile", headerName: "Driver Mobile", width: 150 },
    {
      field: "fareCharge",
      headerName: "Fare Charge",
      width: 120,
      renderCell: (params) => `₹${params.value}`,
    },
    {
      field: "liveLocation",
      headerName: "Live Location",
      width: 180,
      renderCell: (params) =>
        params.value ? (
          <a href={`https://main.holocabs.com/Singlemaps/${params.value}`}>
            LINK
          </a>
        ) : (
          "NOT AVAILABLE"
        ),
    },
    {
      field: "informDriver",
      headerName: "INFORM DRIVER WP",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => OpenWP("driver", params.value)}
        >
          INFORM DRIVER WP
        </Button>
      ),
    },
    {
      field: "informPassenger",
      headerName: "INFORM PASSENGER WP",
      width: 200,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => OpenWP("passenger", params.value)}
        >
          INFORM PASSENGER WP
        </Button>
      ),
    },
    {
      field: "verifyOtp",
      headerName: "Verify OTP by Admin",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => verifyOTP(params.value)}
        >
          Verify
        </Button>
      ),
    },
    { field: "pickupLocation", headerName: "Pickup Location", width: 250 },
    {
      field: "destinationLocation",
      headerName: "Destination Location",
      width: 250,
    },
    {
      field: "distance",
      headerName: "Distance",
      width: 120,
      renderCell: (params) => `${params.value} KM`,
    },
    { field: "rideStatus", headerName: "Ride Status", width: 150 },
    { field: "paymentStatus", headerName: "Payment Status", width: 150 },
    { field: "otpStatus", headerName: "OTP Status", width: 150 },
    { field: "riderOtp", headerName: "RIDER OTP", width: 120 },
    { field: "pickupDistance", headerName: "Pickup Distance", width: 150 },
    { field: "extraDistance", headerName: "Extra Distance", width: 150 },
  ];

  const rows = rideData.map((ride, index) => ({
    id: index,
    rideId: ride.driverHistory._id.slice(-10),
    date: convertISOToIST(ride.driverHistory.date),
    driverAcceptedAt: convertToIST(ride.driverHistory.driveracceptedat),
    rideCompletedAt: convertToIST(ride.driverHistory.ridecompletedat),
    customerName: ride.details.userName,
    customerId: ride.details.getOriginalId,
    driverName: ride.details.driverName,
    vehicleType: ride.details.vehicleType,
    driverId: ride.driverHistory.driverId,
    driverMobile: ride.details.driverMobile,
    fareCharge: ride.details.price,
    liveLocation: ride.driverHistory.rideCompleted
      ? null
      : ride.details.liveLocationKey,
    informDriver: ride.details.driverMobile,
    informPassenger: ride.details.userMobile,
    verifyOtp: ride.driverHistory.driverId,
    pickupLocation: ride.details.plocation,
    destinationLocation: ride.details.dlocation,
    distance: ride.details.distance,
    rideStatus: ride.driverHistory.rideCompleted
      ? "Completed"
      : "Not Completed",
    paymentStatus: ride.driverHistory.paymentCompleted
      ? "Completed"
      : "Not Completed",
    otpStatus:
      ride.details.destinationStart || ride.driverHistory.paymentCompleted
        ? "VERIFIED"
        : "NOT VERIFIED",
    riderOtp: ride.details.otpforValid,
    pickupDistance: ride.details.pickupDistance,
    extraDistance: ride.details.extraDistance,
  }));

  return (
    <div className="list">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="listContainer">
        <div className="navbar">
          <Navbar />
        </div>
        <h1 style={{ padding: 10 }}>Ride Activities</h1>
        <div className="ridehistorybox" style={{ width: "100%" }}>
          <Paper>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              pagination
              checkboxSelection
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default RideHistory;
